import { NgModule, LOCALE_ID } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { DatePipe, registerLocaleData } from '@angular/common';
import localeDeAt from '@angular/common/locales/de-AT';
import { NgxsModule } from '@ngxs/store';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { ViewState } from '../dashboard/store/view.state';
import { PasswordInputComponent } from '../login/password-input/password-input.component';
import { ClockDirective } from './directives/clock.directive';

@NgModule({
  declarations: [
    PasswordInputComponent,
    ClockDirective
  ],
  imports: [
    CommonModule,
    NgbModule,
    FontAwesomeModule,
    ToastrModule.forRoot({
        positionClass: 'toast-top-center',
        timeOut: 3000,
        newestOnTop: false
    }),
    NgxsModule.forRoot([ViewState]),
    NgxsReduxDevtoolsPluginModule.forRoot()
  ],
  exports: [
    FontAwesomeModule,
    PasswordInputComponent,
    ClockDirective
  ],
  providers: [
    DatePipe,
    { provide: LOCALE_ID, useValue: 'de-at' },
  ]
})
export class SharedModule {

    constructor() {
        registerLocaleData(localeDeAt);
    }

}

