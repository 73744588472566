import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../shared/shared.module'
import { LogoutRoutingModule } from './logout-routing.module';

import { LogoutComponent } from './logout.component';


@NgModule({
  declarations: [
    LogoutComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    LogoutRoutingModule
  ],
  exports: [
    SharedModule
  ],
  providers: [

  ]
})
export class LogoutModule { }

