import { Component, OnInit, OnDestroy } from '@angular/core';
import { filter } from 'rxjs/operators';
import { from, fromEvent } from 'rxjs';
import { DataService } from '../core/services/data.service'
import { DatePipe } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { Dashboard } from './dashboard.interface';
import { faPowerOff, faMagic, faMoon, faSyncAlt, faCog } from '@fortawesome/free-solid-svg-icons';
import { Store } from '@ngxs/store';
import { classicView, calendardayView } from './store/view.action';
import { SortDataPipe } from './pipes/sort-data.pipe';
import { VIEWS } from './view-status.enum';
import { LocalStorageService } from '@shared/services/local-storage.service'
import * as moment from 'moment';

@Component({
    selector: 'dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
    providers: [DatePipe, SortDataPipe]
})
export class DashboardComponent implements OnInit, OnDestroy {

    // get current view or set to default view
    currentView: string =  this.localStorageService.get('orange_dashboard_view') || VIEWS.default;
    // interval for updating dashboard
    interval: number;
    // dashboard data
    timeSegments: string[];
    dashboardData: Dashboard;
    timestampOfUpdate: Date;
    // fontawesome
    faPowerOff = faPowerOff;
    faMagic = faMagic;
    faMoon = faMoon;
    faSyncAlt = faSyncAlt;
    faCog = faCog;
    // navbar collapsed
    navCollapsed: boolean;

    constructor(
        private datePipe: DatePipe,
        private sortData: SortDataPipe,
        private activatedRoute: ActivatedRoute,
        private dataService: DataService,
        private localStorageService: LocalStorageService,
        private store: Store) {
    }

    ngOnInit(): void {

        this.init();
        this.updateDashboard();
        this.interval = window.setInterval(() => { this.updateDashboard() }, 200000)
    }

    ngOnDestroy(): void {

        if (this.interval) window.clearInterval(this.interval)
    }

    // dispatcher
    toggleView(): void {
        // toggle state and dispatch
        if ( this.currentView === VIEWS.classic ) {
            this.currentView = VIEWS.calendarday;
            this.store.dispatch(new calendardayView(this.dashboardData));
        }
        else if ( this.currentView === VIEWS.calendarday ) {
            this.currentView = VIEWS.classic;
            this.store.dispatch(new classicView(this.dashboardData));
        }
    }

    // TODO: besser machen
    checkTime(time: string, thead: boolean) {

        if ( this.localStorageService.get('orange_dashboard_view') === VIEWS.calendarday) {
            const currentTime = this.datePipe.transform(new Date(), "HH':00'")
            if (currentTime === time) {
                return 'row-mark'
            } else if (currentTime < time && !thead) {
                return 'yesterday'
            }
        }
    }

    buildTimeSegments(from: string, until: string): string[] {
        const timeSegements = [];
        let start = moment(from);
        let end = moment(until);
        while ( start <= end )  {
            timeSegements.push(moment(start).format('HH:mm'));
            start.add(1, 'hours');
        }

        return timeSegements;
    }

    init() {
        // listen to localstorage changes
        const view$ = fromEvent<StorageEvent>(window, 'storage').pipe(
            // only this key
            filter(event => event.key === 'orange_dashboard_view'),
        ).subscribe(data => {
            // get current dashboard view from local storage
            let action = this.localStorageService.get('orange_dashboard_view');

            if (action) {
                // toggle state and dispatch
                if ( action === VIEWS.classic ) {
                    this.store.dispatch(new classicView(this.dashboardData));
                    this.currentView = VIEWS.calendarday;
                }
                else if ( action === VIEWS.calendarday ) {
                    this.store.dispatch(new calendardayView(this.dashboardData));
                    this.currentView = VIEWS.classic;
                }
            }
        })
    }

    updateDashboard() {
        // get dashboard data from service
        this.dataService.getDashboard().subscribe(
            (data: Dashboard) => {
                // get query parameters "from" and "to"
                this.activatedRoute.queryParams.subscribe(params => {
                    
                    // slice entries to selected range ( - 1 because array starts at 0 )
                    const from = params['from'] ? params['from'] - 1 : 0
                    const to = params['to'] ? params['to'] : data.entries.length
                    data.entries = data.entries.slice(from, to)

                    // hide navbar via parameter
                    this.navCollapsed = params['navbar'] === 'no' ? true : false;
                })
                // build timeSegments
                data.timeSegments = this.buildTimeSegments(data.from, data.until)

                // store the current timeSegments
                this.localStorageService.set('orange_dashboard_timeSegments', data.timeSegments);
                
                // get sorted dashboard data and store them
                let sortedData = this.dashboardData = this.sortData.transform(data);
                this.localStorageService.set('orange_dashboard_data', sortedData);
                
                console.log(this.dashboardData);
                
                // log updates with timestamp in console
                this.timestampOfUpdate = new Date();
                console.log(`[${this.datePipe.transform(this.timestampOfUpdate, 'HH:mm:ss')}] Dashboard update successful!`)
            },
            (err: any) => {
                // log updates with timestamp in console
                this.timestampOfUpdate = new Date();
                console.error(`[${this.datePipe.transform(this.timestampOfUpdate, 'HH:mm:ss')}] Dashboard update failed!`)
            }
        )
    }
}
