import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { catchError, map } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard  {

    constructor(
        private auth: AuthService,
        private router: Router
    ) { }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        // if user is logged in return true
        if (this.auth.isLoggedIn) return true
        // go to login if user not logged in
        return this.auth.validate().pipe(
            map(res => {
                // console.log(res)
                if (res.ok) { return true }
                this.auth.revoke()
                return false
            }),
            catchError((err: HttpErrorResponse) => {
                this.router.navigate([''])
                return of(false)
            })
        )
    }

}
