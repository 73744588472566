import { Observable, throwError } from 'rxjs';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@core/services/auth.service';
import { catchError } from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(
        private router: Router,
        private authService: AuthService
    ) { }

    intercept(
        request: HttpRequest<unknown>, 
        next: HttpHandler
    ): Observable<HttpEvent<unknown>> {

        request = request.clone({
            withCredentials: true
        });

        console.log(request)

        return next.handle(request)

    }
}