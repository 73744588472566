import { Injectable } from '@angular/core';
import { Action, State } from '@ngxs/store';
import { ToastrService } from 'ngx-toastr';
import { classicView, calendardayView } from './view.action';
import { SortDataPipe } from '../pipes/sort-data.pipe';
import { VIEWS } from '../view-status.enum';
import { LocalStorageService } from '@shared/services/local-storage.service';

@State({
    name: 'ViewState',
    defaults: []
})
@Injectable()
export class ViewState {
    constructor(
        private sortData: SortDataPipe,
        private localStorageSerivce: LocalStorageService,
        private toastr: ToastrService ) { }

    @Action(classicView)
    Classic({ getState, setState, patchState }, payload) {
        // store current status and trigger storage event
        this.localStorageSerivce.set('orange_dashboard_view', VIEWS.classic);
        // get sorted dashboard data 
        let sortedData = this.sortData.transform(payload.dashboardData); 
        // store sorted data
        this.localStorageSerivce.set('orange_dashboard_data', sortedData);
        //  overwrite dashboard data with sorted data
        setState({ dashboardData: sortedData });
        // user message
        this.toastr.info(`Auf Ansicht ${VIEWS.classic} gewechselt.`);
    }

    @Action(calendardayView)
    New({ getState, setState, patchState }, payload) {

        this.localStorageSerivce.set('orange_dashboard_view', VIEWS.calendarday);

        let sortedData = this.sortData.transform(payload.dashboardData); 

        this.localStorageSerivce.set('orange_dashboard_data', sortedData);

        setState({ dashboardData: sortedData });

        this.toastr.info(`Auf Ansicht ${VIEWS.calendarday} gewechselt.`);
    }

    
}