<nav class="navbar navbar-dark bg-dbbp" *ngIf="!navCollapsed">
    <div class="container-fluid">
        <a class="navbar-brand" routerLink="/dashboard">
            <img src="/assets/img/db-bahnpark.png" width="309" height="49" alt="">
        </a>
        <div class="d-flex">
            <span class="navbar-text">
                <clock #x="time" class="text-white fw-bold me-4">{{x.clock | date: "dd. MMMM y, HH:mm:ss"}}</clock>
            </span>
            <button type="button" class="btn btn-link text-white" (click)="toggleView()">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-magic" viewBox="0 0 16 16">
                    <path d="M9.5 2.672a.5.5 0 1 0 1 0V.843a.5.5 0 0 0-1 0zm4.5.035A.5.5 0 0 0 13.293 2L12 3.293a.5.5 0 1 0 .707.707zM7.293 4A.5.5 0 1 0 8 3.293L6.707 2A.5.5 0 0 0 6 2.707zm-.621 2.5a.5.5 0 1 0 0-1H4.843a.5.5 0 1 0 0 1zm8.485 0a.5.5 0 1 0 0-1h-1.829a.5.5 0 0 0 0 1zM13.293 10A.5.5 0 1 0 14 9.293L12.707 8a.5.5 0 1 0-.707.707zM9.5 11.157a.5.5 0 0 0 1 0V9.328a.5.5 0 0 0-1 0zm1.854-5.097a.5.5 0 0 0 0-.706l-.708-.708a.5.5 0 0 0-.707 0L8.646 5.94a.5.5 0 0 0 0 .707l.708.708a.5.5 0 0 0 .707 0l1.293-1.293Zm-3 3a.5.5 0 0 0 0-.706l-.708-.708a.5.5 0 0 0-.707 0L.646 13.94a.5.5 0 0 0 0 .707l.708.708a.5.5 0 0 0 .707 0z"/>
                </svg>
            </button>
            <button type="button" class="btn btn-link text-white" routerLink="/logout">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-power" viewBox="0 0 16 16">
                    <path d="M7.5 1v7h1V1z"/>
                    <path d="M3 8.812a5 5 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812"/>
                </svg>
            </button>
        </div>
    </div>
</nav>

<div class="container-fluid">
    <div class="row mt-3">
        <div class="col">
            <table *ngIf="dashboardData" class="dashboard table table-bordered table-sm">
                <thead>
                    <tr>
                        <th *ngFor="let segment of dashboardData.timeSegments" [ngClass]="checkTime(segment, true)">{{segment}}</th>
                        <th>Parkbereich</th>
                        <th>Aktuell</th>
                        <th>Zeit</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let entry of dashboardData.entries">
                        <td *ngFor="let segment of dashboardData.timeSegments; index as i" [ngClass]="checkTime(segment, false)">
                            <div class="card card-body card-sm cat-{{entry.occupancies[i].occupancyCategory}}">
                                <div class="card-main"><span *ngIf="entry.occupancies[i].validData">{{entry.occupancies[i].vacancy}}</span></div>
                                <div class="card-sub"><span *ngIf="entry.occupancies[i].validData">{{entry.occupancies[i].occupancyRate | percent}}</span></div>
                            </div>
                        </td>
                        <td>
                            <div class="card card-body card-sm">
                                <div class="card-main">{{entry.site.station.name}}</div>
                                <div class="card-sub">{{entry.site.name}} ({{entry.currentOccupancy.capacity}})</div>
                            </div>
                        </td>
                        <td>
                            <div class="card card-body card-sm cat-{{entry.currentOccupancy.occupancyCategory}}">
                                <div class="card-main"><span *ngIf="entry.currentOccupancy.validData">{{entry.currentOccupancy.vacancy}}</span></div>
                                <div class="card-sub"><span *ngIf="entry.currentOccupancy.validData">{{entry.currentOccupancy.occupancyRate | percent}}</span></div>
                            </div>
                        </td>
                        <td>
                            <div class="card card-body card-sm">
                                <div class="card-main"><span *ngIf="entry.currentOccupancy.validData">{{entry.currentOccupancy.timeSegment | date: "HH:mm:ss"}}</span></div>
                                <div class="card-sub"><span *ngIf="entry.currentOccupancy.validData">{{entry.currentOccupancy.timeSegment | date: "dd.MM.yyyy"}}</span></div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div *ngIf="!dashboardData" class="text-center text-secondary p-4">
                <div class="spinner-border spinner-border-sm me-2" role="status"></div><b>Lade Dashboard...</b>
            </div>
        </div>
    </div>
</div>
