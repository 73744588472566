import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Dashboard } from '../../dashboard/dashboard.interface';
import { User } from '../../logout/user.interface';
import { Version } from '../../logout/version.interface';
import { environment } from '@environments/environment';

@Injectable({
    providedIn: 'root'
})
export class DataService {

    constructor(
        private http: HttpClient
    ) { }


    getDashboard(): Observable<Dashboard> {
        return this.http.get<Dashboard>(`${environment.apiUrl}/v2/sites/dashboard?channel=DASHBOARD`)
    }

    getUser(): Observable<User> {
        return this.http.get<User>(`${environment.apiUrl}/v2/users/me`)
    }

    getApiStatus(): Observable<Version> {
        return this.http.get<Version>(`${environment.apiUrl}/v2/version`)
    }

}
