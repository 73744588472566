import { Component, OnInit } from '@angular/core';
import { AuthService } from '../core/services/auth.service';
import { DataService } from '../core/services/data.service'
import { User } from './user.interface';
import { Version } from './version.interface';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-logout',
    templateUrl: './logout.component.html',
    styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

    logoutSpin: boolean = false

    basepath: string

    buildNumber: string
    buildTimestamp: string
    code: string
    commitment: string
    dbVersion: string
    version: string

    username: string
    role: string
    firstname: string
    lastname: string

    constructor(
        private auth: AuthService,
        private data: DataService,
        private router: Router,
        private toastr: ToastrService,
        private modalService: NgbModal
    ) { }

    ngOnInit(): void {
        this.data.getUser().subscribe(
            (data: User) => {
                this.username = data.username
                this.role = data.role
                this.firstname = data.firstname
                this.lastname = data.lastname
            }
        )
        this.data.getApiStatus().subscribe(
            (data: Version) => {
                this.basepath = data.basepath
                this.buildNumber = data.buildNumber
                this.buildTimestamp = data.buildTimestamp
                this.code = data.code
                this.commitment = data.commitment
                this.dbVersion = data.dbVersion
                this.version = data.version
            }
        )
    }

    openDocumentationModal(docsModal) {
        this.modalService.open(docsModal, { 
            scrollable: true, 
            size: 'xl',
            centered: true 
        });
    }

    logoutUser(event: { preventDefault: () => void; }) {

        event.preventDefault()

        this.toastr.info('Abmelden...')
        this.logoutSpin = true;

        this.auth.revoke().subscribe(
          (data: any) => {
            this.logoutSpin = false
            this.auth.setLoggedIn(false)
            this.toastr.success('Bis zum nächsten mal!')
            this.router.navigate([''])
            // console.log(data)
          },
          (err: any) => {
            this.logoutSpin = false
          }
        )

    }

}
