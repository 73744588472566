import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    private loggedInStatus = false

    constructor(
        private http: HttpClient
    ) { }

    setLoggedIn(value: boolean) {
        this.loggedInStatus = value
    }

    get isLoggedIn() {
        return this.loggedInStatus
    }

    validate() {
        return this.http.get(`${environment.apiUrl}/v2/users/me`, {
            observe: 'response'
        })
    }

    authorize(username: string, password: string) {
        return this.http.post(`${environment.apiUrl}/v2/auth/login`, {
            'grant_type': 'password',
            'client_id': environment.clientId,
            'username': username,
            'password': password
        })
    }

    revoke() {
        return this.http.post(`${environment.apiUrl}/v2/auth/logout`, {})
    }

}
