import { Dashboard } from '../dashboard.interface';

export class classicView {
    static readonly type = '[View] Classic';
    constructor(
        public dashboardData: Dashboard,
        // public status: string,
    ) {}   
}

export class calendardayView {
    static readonly type = '[View] Calendarday';
    constructor(
        public dashboardData: Dashboard,
        // public status: string
    ) {}
}