import { Directive } from '@angular/core';

@Directive({
  selector: 'clock',
  exportAs: 'time'
})
export class ClockDirective {

  public clock: Date = new Date()

  constructor() { 

    window.setInterval(() => { 
      this.clock = new Date()  
    }, 1000)

  }

}
