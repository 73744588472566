import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Dashboard } from '../dashboard.interface';
import { LocalStorageService } from '@shared/services/local-storage.service'

@Pipe({
    name: 'sortData'
})
export class SortDataPipe implements PipeTransform {

    constructor(
        private datePipe: DatePipe,
        private localStorageService: LocalStorageService
    ) { }

    transform(data: Dashboard): Dashboard {

        let timeSegments: string[];

        switch ( this.localStorageService.get('orange_dashboard_view') ) {
            case '1':
                timeSegments = this.localStorageService.get('orange_dashboard_timeSegments');
                break;
            case '2':
                timeSegments = [
                    "00:00", "01:00", "02:00", "03:00", "04:00", "05:00", "06:00", "07:00", "08:00",
                    "09:00", "10:00", "11:00", "12:00", "13:00", "14:00", "15:00", "16:00", "17:00",
                    "18:00", "19:00", "20:00", "21:00", "22:00", "23:00"
                ];
                break;
            default:
                timeSegments = this.localStorageService.get('orange_dashboard_timeSegments');
        }

        data.entries.map(entry => {
            entry.occupancies.map(serie => {
                serie.timeSegmentSortable = this.datePipe.transform(serie.timeSegment, 'HH:mm')
            })
            entry.occupancies.sort((a, b) => {
                return timeSegments.indexOf(a.timeSegmentSortable) - timeSegments.indexOf(b.timeSegmentSortable)
            })
        })
        data.timeSegments = timeSegments;

        return data;
    }

}