import { Component, OnInit } from '@angular/core';
import { AuthService } from '../core/services/auth.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { faCog, faSignInAlt, faLock } from '@fortawesome/free-solid-svg-icons';
import { LocalStorageService } from '../shared/services/local-storage.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

    faCog = faCog;
    loginIcon = faSignInAlt;
    loginSpin: boolean = false;
    faLock = faLock;

    constructor(
        private auth: AuthService,
        private router: Router, 
        private toastr: ToastrService
    ) { }

    ngOnInit(): void {

    }

    loginUser(event: any) {

        event.preventDefault()

        const target = event.target
        const username = target.querySelector('#username').value
        const password = target.querySelector('#password').value

        this.toastr.info('Anmelden...')
        this.loginIcon = faCog;
        this.loginSpin = true;

        this.auth.authorize(username, password).subscribe(
            (data: any) => {

                this.loginIcon = faSignInAlt
                this.loginSpin = false

                this.auth.setLoggedIn(true)
                this.toastr.success('Authentifizierung erfolgreich. Willkommen!')
                this.router.navigate(['dashboard'])
                
                // this.localStorageService.set('orange_access_token', data.access_token)
                // console.log(data)
            },
            (err: any) => {

                this.loginIcon = faSignInAlt
                this.loginSpin = false
                
                this.auth.setLoggedIn(false)
            }
        )

    }

}
