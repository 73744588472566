import { Component } from '@angular/core';

// component decorator
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
// component logic
export class AppComponent {
  
  title = 'Dashboard'

  constructor() { }

}
