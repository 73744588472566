<nav class="navbar navbar-dark bg-dbbp">
    <a class="navbar-brand" routerLink="/dashboard">
      <img src="/assets/img/db-bahnpark.png" width="309" height="49" alt="">
    </a>
</nav>

<div class="container-fluid h-100">
    <div class="row h-100 justify-content-center align-items-center">
        <div class="card p-0" style="width: 30rem;">
            <div class="card-header">
                <div class="text-muted"><fa-icon [icon]="faLock"></fa-icon> Login</div>
            </div>
            <div class="card-body">
                <form (submit)="loginUser($event)" class="d-grid gap-4">
                    <div class="form-group">
                        <input type="text" placeholder="Benutzername" class="form-control form-control-lg border" id="username">
                    </div>
                    <div class="form-group">                    
                        <password-input></password-input>
                    </div>
                    <button type="submit" class="btn btn-primary btn-lg">
                        Anmelden
                    </button>
                </form>
            </div>
        </div>
    </div>
</div>