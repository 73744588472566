import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from "@angular/core"
import { Observable, of, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { ToastrService } from 'ngx-toastr';

@Injectable({
    providedIn: 'root'
})
export class HttpErrorInterceptor implements HttpInterceptor {

    constructor(
        private toastr: ToastrService
    ) { }

    intercept(
        req: HttpRequest<any>, 
        next: HttpHandler    
    ): Observable<HttpEvent<any>> {

        return next.handle(req).pipe(

            catchError((err: any) => {
                if (err instanceof HttpErrorResponse) {
                    if (err.status === 0 && err.error instanceof ProgressEvent) {
                        // client side
                        try {
                            console.error(`${err.name} ${err.statusText}`);
                            console.error(err.message);
                            this.toastr.error(err.statusText);
                        } catch (e) {
                            console.error('A unknown error has occured. Please try again later.');
                            this.toastr.error('Ein unbekannter Fehler ist aufgetreten. Bitten probieren Sie es später noch einmal.');
                        }
                    } else {
                        // backend side
                        try {
                            console.error(`${err.error.error} ${err.status}`);
                            console.error(`Orange API message: ${err.error.message}`);
                            this.toastr.error(err.error.message);
                        } catch (e) {
                            console.error('A unknown error has occured. Please try again later.');
                            this.toastr.error('Ein unbekannter Fehler ist aufgetreten. Bitten probieren Sie es später noch einmal.');
                        }
                    }
                }
                return throwError(err); // return of(err); // 
            })

        );
    }
}