import { Component } from '@angular/core';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'password-input',
    template: `<div class="input-group">
                    <input type="password" placeholder="Passwort" class="form-control form-control-lg border" id="password">
                    <button class="btn btn-outline-secondary border" type="button" (click)="toggleVisibility()" ngbTooltip="{{toggleTooltip}}" [openDelay]="300" [closeDelay]="0" placement="right">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye-fill" viewBox="0 0 16 16">
                            <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0"/>
                            <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8m8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7"/>
                        </svg>
                    </button>
                </div>`,
    styles: ['.toggle-password:hover { cursor: pointer; }']
})
export class PasswordInputComponent {

    public toggleEye = faEye
    public toggleTooltip = 'Passwort anzeigen'

    constructor() { }

    toggleVisibility(): void {

        const passwordElement: HTMLElement = document.getElementById('password')

        if ( passwordElement.getAttribute('type') === 'password' ) {
            this.toggleEye = faEyeSlash
            this.toggleTooltip = 'Passwort verbergen'
            passwordElement.setAttribute('type', 'text')
        } else {
            this.toggleEye = faEye
            this.toggleTooltip = 'Passwort anzeigen'
            passwordElement.setAttribute('type', 'password')
        }
    }

}
