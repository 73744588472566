import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module'

import { DashboardRoutingModule } from './dashboard-routing.module';
import { DashboardComponent } from './dashboard.component';
import { SortDataPipe } from './pipes/sort-data.pipe';


@NgModule({
    declarations: [
        DashboardComponent,
        SortDataPipe
    ],
    imports: [
        CommonModule,
        SharedModule,
        DashboardRoutingModule,
    ],
    exports: [
        SharedModule
    ],
    providers: [
        SortDataPipe
    ]
})
export class DashboardModule { }

