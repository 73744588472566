<nav class="navbar navbar-dark bg-dbbp">
    <div class="container-fluid">
        <a class="navbar-brand" routerLink="/dashboard">
            <img src="/assets/img/db-bahnpark.png" width="309" height="49" alt="">
        </a>
        <div class="d-flex">
            <span class="navbar-text">
                <clock #x="time" class="text-white fw-bold me-4">{{x.clock | date: "dd. MMMM y, HH:mm:ss"}}</clock>
            </span>
            <button type="button" class="btn btn-link text-white" routerLink="/dashboard">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"/>
                </svg>
            </button>
        </div>
    </div>
</nav>

<div class="container-fluid h-100">
    <div class="row h-100 justify-content-center align-items-center">

        <div class="card p-0" style="width: 30rem;">
            <div class="card-header">
                {{firstname}} {{lastname}} <small>({{role}})</small>
            </div>
            <div class="card-body">
                <div class="d-grid gap-3">
                    <button type="button" class="btn btn-primary" (click)="logoutUser($event)">
                        Abmelden
                    </button>
                    <button class="btn btn-outline-primary" (click)="openDocumentationModal(docsModal)" type="button">
                        Dokumentation
                    </button>
                </div>
            </div>
        </div>
    </div>

    <footer class="footer text-center">
        <div class="pb-2">
            <code>
                🍊 API Version {{version}} //
                <!-- <fa-icon [icon]="faAngular" size="lg" class="text-danger"></fa-icon> -->
                🤖 GUI Version 2.0.0-beta //
                💾 {{basepath}}             
            </code>
        </div>
        <div class="text-muted">{{commitment}}</div>
    </footer>
</div>


<ng-template #docsModal let-modal>
    <div class="modal-header">
      <h4 class="modal-title">Dokumentation</h4>
    </div>
    <div class="modal-body">
        <p>Optionen:</p>
        <table class="table table-sm table-striped table-bordered">
            <thead>
                <tr>
                    <th>Parameter</th>
                    <th>Beschreibung</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><span class="badge text-bg-secondary">from</span></td>
                    <td>Legt fest, bei welchem Parkbereich begonnen werden soll.</td>
                </tr>
                <tr>
                    <td><span class="badge text-bg-secondary">to</span></td>
                    <td>Legt fest, bis zu welchem Parkbereich anzeigt werden soll.</td>
                </tr>
                <tr>
                    <td><span class="badge text-bg-secondary">navbar</span></td>
                    <td>Zeigt die Navigationsleiste an oder versteckt sie.</td>
                </tr>
            </tbody>
        </table>
        
        <p>Demo:</p>
        
        <code><a href="/#/dashboard?from=1&to=23" target="_blank">/#/dashboard?from=1&to=23</a></code><br>
        <code><a href="/#/dashboard?from=24&to=48&navbar=no" target="_blank">/#/dashboard?from=24&to=47&navbar=no</a></code><br>
        <code><a href="/#/dashboard?from=49&navbar=no" target="_blank">/#/dashboard?from=48&navbar=no</a></code>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Schlie&szlig;en</button>
    </div>
  </ng-template>